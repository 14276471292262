import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'

import { TailwindContext } from './context/TailwindContext'
import { theme, getBreakpointInPixels } from './services/tailwind'
import { AppProvider } from './context/AppContext'

const In = React.lazy(() => import('./pages/in/In'))
const Auth = React.lazy(() => import('./pages/auth/Auth'))
const QrCode = React.lazy(() => import('./pages/qr/QrCode'))
const Impersonate = React.lazy(() => import('./Impersonate'))

const queryClient = new QueryClient()

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/app/in/*" element={<React.Suspense><In /></React.Suspense>} />
      <Route path="/app/:locale/*" element={<React.Suspense><Auth /></React.Suspense>} />
      <Route path="/app/qr/:locale/*" element={<React.Suspense><QrCode /></React.Suspense>} />
      <Route path="/app/user/login" element={<React.Suspense><Impersonate /></React.Suspense>} />
      <Route path="*" element={<Navigate to="/app/en" />} />
    </>
  )
)

export default function App () {
  return (
    <TailwindContext.Provider value={{ theme, getBreakpointInPixels }}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <RouterProvider router={router} />
        </AppProvider>
      </QueryClientProvider>
    </TailwindContext.Provider>
  )
}
